import React from 'react'
import { Flex, Box } from 'reflexbox'
import SbEditable from 'storyblok-react'

import Markdown from 'src/components/Markdown/Markdown'
import { Container } from 'src/components/UI/Grid/Grid'
import ButtonBorder from 'src/components/UI/Button/ButtonBorder/ButtonBorder'
import Image from 'src/components/UI/Image/Image'
import { getLinkAttributes } from 'src/helpers/getLinkAttributes'

import * as styles from './404.module.scss'
import { Props } from './types'

const NotFoundModule = ({ blok }: Props): JSX.Element => {
  const { background, title, text, button } = blok
  const { url, title: linkTitle } =
    button && button?.length > 0 ? getLinkAttributes(button[0]) : {}
  return (
    <SbEditable content={blok}>
      <Flex
        alignItems="center"
        justifyContent="center"
        className={styles.wrapper}
      >
        {background && background.filename && (
          <Image
            className={styles.background}
            src={background.filename}
            alt="Page not found"
            sizes={{
              mobile: '100vw',
              tablet: '100vw',
              desktop: '100vw',
            }}
          />
        )}
        <Box>
          <Container>
            <Flex
              maxWidth="444px"
              width={1}
              textAlign="center"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Box marginBottom={[6]} as="h1" className={styles.title}>
                {title && title}
              </Box>
              <Box marginBottom={[6]} as="p">
                {text && <Markdown source={text} />}
              </Box>
              <Box maxWidth="165px">
                <ButtonBorder to={url} title={linkTitle}>
                  {linkTitle}
                </ButtonBorder>
              </Box>
            </Flex>
          </Container>
        </Box>
      </Flex>
    </SbEditable>
  )
}

export default NotFoundModule
